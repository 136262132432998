<template>
    <v-dialog v-model="show"
              scrollable
              max-width="640"
              mih-height="480">
        <v-card class="ev-vehicles">
            <v-toolbar dark color="primary"
                       elevation="0">
                <v-autocomplete placeholder="задать новый статус"
                          :items="statuses"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          return-object
                          v-model="status"
                          hide-details></v-autocomplete>
                <v-text-field placeholder="госзнак/стоянка"
                              dense
                              hide-details
                              v-on:input="search"
                              clearable
                              style="margin-left:1rem;"></v-text-field>
                <v-spacer />
                <v-btn icon
                       v-on:click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-list flat
                        subheader>
                    <v-subheader>
                        <v-spacer />
                        <v-list-item-action>
                            <v-checkbox indeterminate 
                                        v-on:change="setall"
                                        :value="false" />
                        </v-list-item-action>
                    </v-subheader>
                    <v-list-item v-for="i in items"
                                 :key="i.evacoffensejournalId"
                                 :class="{'error': (!!i.error)}">
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="auto">
                                        {{ i.evacoffensejournalRegnum }}
                                        <div class="dt">
                                            {{ format(i.evacoffensejournalCreatedt) }}
                                        </div>
                                    </v-col>
                                    <v-col cols="2">
                                        {{ i.evacoffensejournalStateidName }}
                                    </v-col>
                                    <v-col>
                                        <span class="gov">
                                            {{ i.evacoffensejournalVehicleregnum }}
                                        </span>
                                        <span class="kind">{{ i.evacoffensejournalVehiclekindname }}</span>
                                        <div class="vc-meta">
                                            <span class="addr">{{ i.evacoffensejournalOffenseaddress }}
                                                <template v-if="!!i.evacoffensejournalParkingidAddress">
                                                    &nbsp;<v-icon small>mdi-chevron-right</v-icon>&nbsp;
                                                    {{ i.evacoffensejournalParkingidAddress }}
                                                </template>
                                            </span>
                                        </div>
                                        <div v-if="!!i.error" class="error-message">
                                            {{ i.error.message }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-checkbox :input-value="i.checked" 
                                            v-on:change="checked($event, i)" />
                            </v-list-item-action>
                    </v-list-item>    
                </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn small outlined color="gray"
                       v-on:click="show = false">
                    закрыть
                </v-btn>
                <v-btn small color="primary"
                       v-on:click="save"
                       :loading="mode === MODES.loading">
                    <v-badge :content="selected() || '-'">
                        сохранить
                    </v-badge>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { isEmpty, MODES } from "@/utils";
import { post, prepareSinResponse } from "@/utils/http";
const $moment = require('moment');

var hTimer = false;
var _statuses = null;

export default {
    name: 'ToArchive',
    data(){
        return {
            MODES,
            status: null,
            statuses: null,
            mode: MODES.default,
            s: null,
            show: false,
            all: null
        };
    },
    mounted(){
        this.all = jet.collections.active.master.rows;
        this.show = (new Date()).getTime();
        if (!!_statuses){
            this.statuses = [..._statuses];
            return;
        }
        (async ()=>{
            try{
                const opts = {
                    type: 'core-read',
                    query: "sin2:/v:a5603bce-4795-432b-b2c3-ce9323773015?sort=evacrequeststatus.name"
                };
                const resp = await post(opts);
                if (resp.error){
                    throw resp.error;
                }
                _statuses = prepareSinResponse(resp);
                this.statuses = [..._statuses];
            } catch(e){
                console.log('ERR (statuses)', e);
            }
        })();
    },
    computed: {
        items(){
            if ( isEmpty(this.s) ){
                return this.all;
            }
            const re = new RegExp(`(${this.s})+`, 'gi');
            return this.all.filter( a => ( re.test(a.evacoffensejournalVehicleregnum) ||  re.test(a.evacoffensejournalParkingidAddress) ) );
        }
    },
    methods: {
        format(dt){
            return $moment(dt).format('DD.MM.YYYY HH:mm');
        },
        selected(){
            return this.all?.filter( item => (!!item.checked) ).length;
        },
        setall(val){
            this.items.forEach( item => {
                item.checked = val;
            });
            this.$forceUpdate();
        },
        checked(checked, item){
            item.checked = checked;
            this.$forceUpdate();
        },
        search(s){
            if (!!hTimer){
                clearTimeout(hTimer);
                hTimer = setTimeout( s => {
                    hTimer = false;
                    this.s = s;
                }, 500 );
            } else {
                this.s = s;
            }
        },
        save(){
            if ( isEmpty(this.status?.id) ){
                jet.msg({text:"Задайте статус", timeout:10000});
                return;
            }
            
            const resps= [];
            this.mode = MODES.loading;
            this.s = null; //reset search
            
            this.$nextTick(async ()=>{
                try {
                    
                    this.all.filter(item => !!item.checked ).forEach( item => {
                        resps.push(post({
                                            type: 'core-update',
                                            query: 'sin2:/v:8190818d-bf31-41d3-8e3c-08582b85f7e9',
                                            params: [
                                                { id: 'ID', type: 'id', value: item.evacoffensejournalId },
                                                { id: 'stateId', type: 'id', value: this.status.id }
                                            ]
                        }));
                    });
                    const res = await Promise.all(resps);
                    console.log('saved', res);
                    this.show = false;
                    var saved = res.length;
                    jet.msg({text: (saved > 0) ? `Изменено ${saved} записей` : 'Ничего не изменено!', timeout: 10000});
                    jet.collections.active.refresh();
                } catch(e) {
                    jet.msg({text: 'При обновлении возникли ошибки, проверьте состояние записей', timeout: 10000, color: 'warning'});
                }
                finally {
                    this.mode = MODES.default;
                }
            });
        }
    }
}    
</script>
<style lang="scss" scoped>
    .ev-vehicles{
        & .v-list{
            &-item{
                &.error{
                    background: #ffebee;
                    &-message{
                        color: #fff;
                    }
                }
                & .row {
                    flex-wrap: nowrap;
                    align-items: center;
                    & .col {
                        padding: 0.25rem;
                        &:first-child{
                            width: 5rem;
                            text-align: center;
                            font-size: 1.25rem;
                            & .dt{
                                margin-top: 0.5rem;
                                font-size: 0.55rem;
                            }
                        }
                        & .gov{
                            text-transform: uppercase;
                            font-size: 1rem;
                            font-weight: 500;
                            margin-right: 1rem;
                            & .kind{
                                font-weight: 400;
                                font-size: 0.85rem;
                            }
                            
                        }
                        & .vc-meta{
                            font-size: 0.7rem;
                            display: flex;
                            margin-top: 0.5rem;
                            & > * {
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
                &:not(:last-child) {
                    border-bottom: 1px solid #ccc;
                }
            }  /*-item*/
        }
        & .v-card__text{
            min-height: 480px;
        }
        & .v-card__actions{
            justify-content: flex-end;
        }
    }
</style>